// THREE
import * as THREE from 'three';
import { CSS2DObject } from 'three/examples/jsm/renderers/CSS2DRenderer';

// Giro3d Extensions
import { LayerState, canShowInMinimap, hasProgress } from 'types/LayerState';
import { DatasetId, OLFeatureId, SourceFileId, ZoomFactor, fromBox3, fromVector3, toVector3 } from 'types/common';
import { AnnotationId } from 'types/Annotation';
import { ColorLayer } from '@giro3d/giro3d/core/layer';
import VectorLayer from 'giro3d_extensions/layers/raster/VectorLayer';
import IsClickable, { isClickable } from 'giro3d_extensions/layers/IsClickable';
import { Feature } from 'ol';
import { Coordinate } from 'ol/coordinate';
import HoveredItem from 'types/HoveredItem';
import { EventBus, EventMap, useEventBus } from 'EventBus';
import Layer, { HostView } from 'giro3d_extensions/layers/Layer';
import Dataset from 'types/Dataset';
import StateObserver from 'giro3d_extensions/layers/StateObserver';
import { Dispatch, RootState } from 'store';
import { Extent } from '@giro3d/giro3d/core/geographic';
import { MapPickResult } from '@giro3d/giro3d/core/picking';
import { useServiceContainer } from 'ServiceContainer';
import { doLoadDataset, setClickedDataset } from 'redux/actions';
import { SourceFile } from 'types/SourceFile';
import IsHoverable from 'giro3d_extensions/layers/IsHoverable';
import Orientation from '../giro3d_extensions/Orientation';
import LayerManager from '../giro3d_extensions/LayerManager';
import { DRAWTOOL_STATE } from '../giro3d_extensions/DrawTool';
import Compass from '../giro3d_extensions/Compass';

import { DEFAULT_GIRO3D_SETTINGS, TICKS_PRESETS } from './Constants';
import { highlightedStyleBuilder } from './VectorStyle';

import * as giro3d from '../redux/giro3d';
import * as graphicsSettings from '../redux/graphicsSettings';
import * as layersSlice from '../redux/layers';
import * as datasetsSlice from '../redux/datasets';
import * as annotationsSlice from '../redux/annotations';

import Controls from './Controls';

import BaseGiro3dService, { PickedPoint, isGiro3dMap } from './BaseGiro3dService';
import MinimapService from './MinimapService';
import SeismicService from './SeismicService';
import { SelectionItem } from '../redux/giro3d';
import Picker from './Picker';
import FeatureManager from './FeatureManager';
import ViewManager from './ViewManager';
import { AnnotationManagerImpl } from './AnnotationManager';
import { DrawingManagerImpl } from './DrawingManager';

const eventBus = useEventBus();

class Giro3dService extends BaseGiro3dService implements Picker, FeatureManager, ViewManager {
    private readonly _currentSelection: SelectionItem[];
    private _clickableVector: boolean;
    private _layerLoading: Map<DatasetId, boolean> = new Map();
    private _compassWidget: Compass;
    private _orientationGizmo: Orientation;
    private _minimapService: MinimapService;
    private _seismicService: SeismicService;
    private _giro3dProgress = -1;
    private _enableContourLines: boolean;
    private _contourLinePrimaryInterval: number;
    private _contourLineSecondaryInterval: number;
    private _contourLineOpacity: number;
    private _contourLineColor: THREE.Color;
    private _rightHeld: boolean;
    private _selectOpen: boolean;
    private _instanceUpdatedFrameReq: () => void;
    private _cursorMarker: CSS2DObject;
    private _cameraMoveFrameReq: () => void;
    private _sceneVolume: THREE.Box3;
    private readonly _cameraPosition = {
        position: new THREE.Vector3(),
        target: new THREE.Vector3(),
    };
    private _onGoToLayer: (e: { layer: string }) => void;
    private _onGoToFile: (e: { dataset: string; file: string }) => void;
    private _onHighlight: (e: { layer: string }) => void;
    private _onHighlightFile: (e: { dataset: string; file: string }) => void;
    private _onLayerInitialized: () => void;
    private _onSaveCameraState: (e: unknown) => void;
    private _onRestoreCameraState: (e: unknown) => void;
    private _onZoom: (e: { factor: ZoomFactor }) => void;
    private _onGoToBbox: (e: { bbox: THREE.Box3 }) => void;
    private _onCloseContextMenu: () => void;

    private _hoveredCSSAnnotation: boolean;

    constructor() {
        super({ hostView: HostView.MainView, canHover: true });

        this._annotationManager = new AnnotationManagerImpl(this._hostView);
        this._drawingManager = new DrawingManagerImpl();

        // Point annotations are CSS elements so are picked differently than regular 3D objects.
        this._annotationManager.addEventListener('hovered', (e) => this.setHoveredCSSAnnotation(e));

        this._clickableVector = DEFAULT_GIRO3D_SETTINGS.CLICKABLE_VECTOR_FEATURES;
        this._currentSelection = [];
        this._compassWidget = undefined;
        this._orientationGizmo = undefined;
        this._minimapService = undefined;
        this._seismicService = undefined;
        this._enableContourLines = DEFAULT_GIRO3D_SETTINGS.CONTOUR_LINES;
        this._contourLinePrimaryInterval = DEFAULT_GIRO3D_SETTINGS.CONTOUR_LINES_PRIMARY_INTERVAL;
        this._contourLineSecondaryInterval = DEFAULT_GIRO3D_SETTINGS.CONTOUR_LINES_SECONDARY_INTERVAL;
        this._contourLineOpacity = DEFAULT_GIRO3D_SETTINGS.CONTOUR_LINES_OPACITY;
        this._contourLineColor = DEFAULT_GIRO3D_SETTINGS.CONTOUR_LINES_COLOR;
        this._rightHeld = false;
        this._selectOpen = false;

        const container = useServiceContainer();

        container.register('Picker', this);
        container.register('FeatureManager', this);
        container.register('MainViewManager', this);
        container.register('AnnotationManager', this._annotationManager);
        container.register('DrawingManager', this._drawingManager);
    }

    private setHoveredCSSAnnotation(arg: EventMap['hovered-item']) {
        this.resetPreviouslyHovered();
        this._hoveredCSSAnnotation = arg?.item != null;
        this.setHoveredTooltip(arg.item, arg.location);
    }

    protected setZScale(scale: number) {
        if (!this._instance) {
            return;
        }
        super.setZScale(scale);
        this.computeSceneVolume();
    }

    protected initLayerManager() {
        return new LayerManager({
            instance: this._instance,
            segments: this._segments,
            hillshading: this._enableHillshading,
            hillshadingIntensity: this._hillshadeIntensity,
            azimuth: this._lightDirection.azimuth,
            zenith: this._lightDirection.zenith,
            contourLines: this._enableContourLines,
            contourLinePrimaryInterval: this._contourLinePrimaryInterval,
            contourLineSecondaryInterval: this._contourLineSecondaryInterval,
            contourLineOpacity: this._contourLineOpacity,
            contourLineColor: this._contourLineColor,
            onVolumeChanged: this.computeSceneVolume.bind(this),
            projectExtent: this._stateObserver.select(datasetsSlice.getProjectExtent),
        });
    }

    private computeSceneVolume() {
        queueMicrotask(() => {
            this._sceneVolume = this.getBoundingBox();
            this._dispatch(giro3d.setVolume(fromBox3(this._sceneVolume)));
            this._controlsInspector?.setBoundingBox(this._sceneVolume);
        });
    }

    protected initControls() {
        return new Controls(this._instance, this.getPointAt.bind(this), this.getBoundingBox.bind(this));
    }

    pickPoint(mouse: MouseEvent): THREE.Vector3 {
        const result = this.getPointAt(mouse);

        if (result) {
            return result.point;
        }

        return null;
    }

    init(
        domElem: HTMLDivElement,
        inspectorDomElem: HTMLDivElement,
        extent: Extent,
        dispatch: Dispatch,
        controlsDomElem: HTMLDivElement
    ) {
        super.init(domElem, inspectorDomElem, extent, dispatch);

        this._instanceUpdatedFrameReq = this.onInstanceUpdated.bind(this);
        this._instance.addEventListener('update-end', this._instanceUpdatedFrameReq);

        this._compassWidget = new Compass(this._instance, this._controls, controlsDomElem);

        this._drawingManager.init(this._instance, this._dispatch, this.getPointAt.bind(this), eventBus);
        this._annotationManager.init(
            this._instance,
            dispatch,
            this._drawingManager,
            eventBus,
            this._layerManager,
            this._stateObserver
        );

        this._instance.viewport.addEventListener('pointerdown', this.pointerDownHandler.bind(this));
        this._instance.viewport.addEventListener('pointerup', this.pointerUpHandler.bind(this));

        const cursorPoint = document.createElement('div');
        cursorPoint.style.position = 'absolute';
        cursorPoint.style.borderRadius = '50%';
        cursorPoint.style.width = '1rem';
        cursorPoint.style.height = '1rem';
        cursorPoint.style.backgroundColor = '#ff0000';
        cursorPoint.style.border = '1px solid #ffffff';
        cursorPoint.style.pointerEvents = 'none';

        this._cursorMarker = new CSS2DObject(cursorPoint);
        this._cursorMarker.visible = false;
        this._instance.add(this._cursorMarker);

        this._orientationGizmo = new Orientation(this._instance, this._controls, { zScale: this.getZScale() });
        this._orientationGizmo.visible = false;
        this._orientationGizmo.update();

        this._dispatch(giro3d.setInitialized(true));
    }

    protected override unsubscribeFromEventBus(bus: EventBus): void {
        super.unsubscribeFromEventBus(bus);

        bus.unsubscribe('close-context-menu', this._onCloseContextMenu);
        bus.unsubscribe('go-to-layer', this._onGoToLayer);
        bus.unsubscribe('go-to-file', this._onGoToFile);
        bus.unsubscribe('go-to-bbox', this._onGoToBbox);
        bus.unsubscribe('highlight-layer', this._onHighlight);
        bus.unsubscribe('highlight-file', this._onHighlightFile);
        bus.unsubscribe('layer-initialized', this._onLayerInitialized);
        bus.unsubscribe('save-camera-state', this._onSaveCameraState);
        bus.unsubscribe('restore-camera-state', this._onRestoreCameraState);
        bus.unsubscribe('zoom-camera', this._onZoom);
    }

    protected override subscribeToEventBus(bus: EventBus): void {
        super.subscribeToEventBus(bus);

        this._onGoToLayer = this.onGoToLayer.bind(this);
        this._onGoToFile = this.onGoToFile.bind(this);
        this._onHighlight = this.onHighlight.bind(this);
        this._onHighlightFile = this.onHighlightFile.bind(this);
        this._onLayerInitialized = this.onLayerInitialized.bind(this);
        this._onSaveCameraState = this.saveControlsState.bind(this);
        this._onRestoreCameraState = this.restoreControlsState.bind(this);
        this._onZoom = this.onZoom.bind(this);
        this._onGoToBbox = this.onGoToBbox.bind(this);
        this._onCloseContextMenu = this.closeContextMenu.bind(this);

        bus.subscribe('close-context-menu', this._onCloseContextMenu);
        bus.subscribe('save-camera-state', this._onSaveCameraState);
        bus.subscribe('restore-camera-state', this._onRestoreCameraState);
        bus.subscribe('go-to-layer', this._onGoToLayer);
        bus.subscribe('go-to-file', this._onGoToFile);
        bus.subscribe('go-to-bbox', this._onGoToBbox);
        bus.subscribe('highlight-layer', this._onHighlight);
        bus.subscribe('highlight-file', this._onHighlightFile);
        bus.subscribe('layer-initialized', this._onLayerInitialized);
        bus.subscribe('zoom-camera', this._onZoom);
    }

    private onZoom(e: { factor: ZoomFactor }) {
        this.zoom(e.factor);
    }

    private onHighlight(e: { layer: string }) {
        this.highlight(e.layer);
    }

    private onHighlightFile(e: { dataset: string; file: string }) {
        this.highlightFile(e.dataset, e.file);
    }

    private onGoToLayer(e: { layer: string }) {
        this.goToLayer(e.layer);
    }

    private onGoToFile(e: { dataset: string; file: string }) {
        this.goToFile(e.dataset, e.file);
    }

    private onGoToBbox(e: { bbox: THREE.Box3 }) {
        this.goToBBox(e.bbox);
    }

    protected onStateObserverCreated(observer: StateObserver<RootState>): void {
        super.onStateObserverCreated(observer);

        observer.subscribe(giro3d.getControlMode, (v) => this.setControlsMode(v));
        observer.subscribe(giro3d.getControlsTarget, (v) => this.setControlsTarget(toVector3(v)));

        observer.subscribe(giro3d.getZScale, (v) => this.setZScale(v));
        observer.subscribe(giro3d.getCamera, (v) => this.setCamera(v.position, v.target));
        observer.subscribe(giro3d.isClickableVectorFeatureEnabled, (v) => this.setIsVectorClickable(v));

        observer.subscribe(annotationsSlice.active, (annotation) => this.setSelectedAnnotation(annotation?.id));

        // Contour lines
        observer.subscribe(graphicsSettings.isContourLinesEnabled, (v) => this.setContourLines(v));
        observer.subscribe(graphicsSettings.getContourLineColor, (v) => this.setContourLineColor(v));
        observer.subscribe(graphicsSettings.getContourLineOpacity, (v) => this.setContourLineOpacity(v));
        observer.subscribe(graphicsSettings.getContourLinePrimaryInterval, (v) =>
            this.setContourLinePrimaryInterval(v)
        );
        observer.subscribe(graphicsSettings.getContourLineSecondaryInterval, (v) =>
            this.setContourLineSecondaryInterval(v)
        );

        observer.subscribe(
            layersSlice.all,
            (v) => this.loadUnloadLayers(v),
            (l, r) => this.layersChangeTest(l, r)
        );
    }

    private setSelectedAnnotation(id: AnnotationId) {
        this._annotationManager.setSelectedAnnotation(id);
    }

    private loadUnloadLayers(layers: LayerState[]) {
        // Load layers
        layers
            .filter((l) => l.visible && !this._loadedLayers.has(l.datasetId))
            .forEach((layerToLoad) => {
                const dataset = this._stateObserver.select(datasetsSlice.get(layerToLoad.datasetId));
                if (!this._loadedLayers.has(layerToLoad.datasetId)) {
                    this._loadedLayers.add(dataset.id);
                    doLoadDataset(this._dispatch, dataset);
                }
            });

        // Unload layers
        [...this._loadedLayers]
            .filter((id) => !layers.map((l) => l.datasetId).includes(id))
            .forEach((id) => this.removeLayer(id));
    }

    private layersChangeTest(left: LayerState[], right: LayerState[]): boolean {
        const loadedLayerIds = new Set([...this._loadedLayers]);

        const leftVisibleUnloaded = new Set(
            left.filter((l) => l.visible && !loadedLayerIds.has(l.datasetId)).map((l) => l.datasetId)
        );
        const rightVisibleUnloaded = new Set(
            right.filter((l) => l.visible && !loadedLayerIds.has(l.datasetId)).map((l) => l.datasetId)
        );

        const loadedLayersNotInRight = [...this._loadedLayers].filter(
            (id) => !right.map((l) => l.datasetId).includes(id)
        );

        return (
            loadedLayersNotInRight.length === 0 &&
            leftVisibleUnloaded.size === rightVisibleUnloaded.size &&
            [...leftVisibleUnloaded].every((v) => rightVisibleUnloaded.has(v))
        );
    }

    private reportCameraPosition() {
        const position = this._controls.getPosition(new THREE.Vector3());
        const target = this._controls.getTarget(new THREE.Vector3());
        if (!this._cameraPosition.position.equals(position) || !this._cameraPosition.target.equals(target)) {
            this._cameraPosition.position = position;
            this._cameraPosition.target = target;
            this._dispatch(giro3d.setCamera({ position: fromVector3(position), target: fromVector3(target) }));
        }
    }

    private onInstanceUpdated() {
        const progress = this._instance.progress;
        if (progress >= 1 || Math.abs(this._giro3dProgress - progress) > 0.05) {
            this._dispatch(giro3d.setProgress(this._instance.progress));
            this._giro3dProgress = progress;
        }

        this.reportLayerProgress();
        this.reportCameraPosition();
    }

    private reportLayerProgress() {
        for (const layer of this.getAllLayers()) {
            const layerState = layer.layerState;

            if (hasProgress(layerState)) {
                const value = layer.getLoading();

                const id = layer.datasetId;

                if (!this._layerLoading.has(id)) {
                    this._layerLoading.set(id, value);
                }

                if (this._layerLoading.get(id) !== value) {
                    this._dispatch(layersSlice.setLoading({ layer: layerState, value }));
                    this._layerLoading.set(id, value);
                }
            }
        }
    }

    private cameraMoveHandler() {
        this._instance.camera.camera3D.getWorldDirection(this._tmpVec3);
        this._minimapService?.setCameraMarker({
            ...(!this._seismicService || this._seismicService.getLayers().size === 0
                ? this._instance.camera.camera3D.position
                : { x: null, y: null, z: null }),
            rotation: Math.atan2(this._tmpVec3.x, this._tmpVec3.y),
        });
    }

    private pointerDownHandler(event: PointerEvent) {
        if (event.button === 2) this._rightHeld = true;
    }

    private async pointerUpHandler(event: PointerEvent) {
        if (event.button === 2 && this._rightHeld && this._drawingManager.state === DRAWTOOL_STATE.READY) {
            const point = this.getPointAt(event);

            if (!point.picked) {
                if (this._selectOpen) this.closeContextMenu();
                return;
            }

            const result = await this.getIntersectingDatasetIdFileId(point.pickResult);

            if (result) {
                const { dataset, sourceFile } = result;
                this._dispatch(setClickedDataset(dataset, sourceFile));

                this._dispatch(
                    giro3d.setContextMenu({
                        open: true,
                        offset: { x: event.x - 65, y: event.y }, // 65px offset from left menu bar
                        dataset,
                        sourceFile,
                        point: fromVector3(point?.point as THREE.Vector3),
                    })
                );
                this._selectOpen = true;
            } else if (point.pickResult.object?.userData.datasetId) {
                // No action here until we add annoation functions to the select tool
                // this.dispatch({
                //     type: GIRO3D_SELECT_TOOL,
                //     payload: {
                //         open: true,
                //         offset: { x: event.x - 65, y: event.y }, // 65px offset from left menu bar
                //         feature: point.object.parentLayer,
                //     },
                // });
                // this.selectOpen = true;
                this.closeContextMenu();
            } else if (this._selectOpen) this.closeContextMenu();
        }
    }

    closeContextMenu() {
        if (this._selectOpen) {
            this._selectOpen = false;
            this._dispatch(giro3d.setContextMenu({ open: false }));
        }
    }

    async clickHandler(event: MouseEvent) {
        // Let base handle dataset clicked first
        const point = await super.clickHandler(event);

        this._rightHeld = false;
        if (this._selectOpen) this.closeContextMenu();

        const clickableLayer = this.firstClickableLayerAtPosition(event);
        if (clickableLayer) {
            await clickableLayer.clickHandler();
            this.doUnselectFeatures();
        } else {
            if (!this._clickableVector) {
                this.doUnselectFeatures();
                return null;
            }

            if (isGiro3dMap(point?.pickResult?.entity)) {
                const mapResult = point.pickResult as MapPickResult;
                const coord = mapResult.coord;
                const tile = mapResult.object;

                const features: SelectionItem[] = this._layerManager
                    .getVectorAtCoordinate(mapResult.entity, coord, 10, tile)
                    .filter((f) => f.layer.visible)
                    .map((f) => ({ layer: f.layer.userData.datasetId, feature: f.feature }));

                if (features.length > 0) {
                    this.doSelectFeatures(features, point.point);
                } else {
                    this.doUnselectFeatures();
                }
            } else {
                this.doUnselectFeatures();
            }
        }

        return point;
    }

    protected getClickableLayer(id: string, fileId?: string): Layer & IsClickable {
        // First, check ordinary layers
        if (this._layers.has(id)) {
            const datasetLayers = this._layers.get(id);
            if (datasetLayers.length > 0) {
                const index = fileId ? datasetLayers.findIndex((d) => d.sourceFileId === fileId) : 0;
                const datasetLayer = datasetLayers[index];
                if (isClickable(datasetLayer)) return datasetLayer;
            }
        }

        // Then check annotations
        const annotation = this._annotationManager.getAnnotation(id);
        if (annotation) {
            return annotation.layer;
        }

        return null;
    }

    protected override hoverLayer(mouseEvent: MouseEvent, layer: Layer & IsHoverable, hover: boolean): void {
        if (this._hoveredCSSAnnotation) {
            // Point annotations are picked using DOM events rather than raycasting,
            // so we need a custom logic for that. Whenever a DOM annotation is picked,
            // we need to un-hover 3D objects to avoid double hovering (DOM annotation + 3D object)
            if (this._previouslyHovered) {
                this._previouslyHovered.hover(false);
                this._previouslyHovered = null;
            }
            return;
        }

        super.hoverLayer(mouseEvent, layer, hover);

        if (hover) {
            this.setHoveredTooltip(layer.getHoverInfo(), { x: mouseEvent.clientX, y: mouseEvent.clientY });
        } else {
            this.setHoveredTooltip(null, null);
        }
    }

    protected onInteraction() {
        super.onInteraction();

        this.closeContextMenu();
        this._rightHeld = false;

        const target = new THREE.Vector3();

        this._controls.getTarget(target);
        const distance = this._instance.camera.camera3D.position.distanceTo(target) / 10;
        this._orientationGizmo.setAxesHelperSize(
            TICKS_PRESETS.sort((a, b) => Math.abs(a - distance) - Math.abs(b - distance))[0],
            this.getZScale()
        );

        if (!this._orientationGizmo.visible) {
            // We actually started an interaction
            if (this._controls.cameraControls.active || this._controls.cameraControls.currentAction !== 0) {
                this._drawingManager.pause();
            }

            this._orientationGizmo.visible = true;
            this._orientationGizmo.update();

            if (this._interactionTimer !== null) {
                // We already have an interaction pending
                // Clear previous interaction timer (will be retriggered at the end of this animation)
                clearTimeout(this._interactionTimer);
                return;
            }

            if (this._interactionTransparency) {
                this.eachInteractableLayer((l) => {
                    try {
                        const previous = l.getOpacity();
                        this._previousOpacities.set(l.datasetId, previous);
                        l.setOpacity(Math.min(0.5, previous));
                    } catch (error) {
                        if (error.name !== 'NotImplementedError') throw error;
                    }
                });
            }
        }
    }

    protected onInteractionEnd() {
        if (this._interactionTimer !== null) {
            // There was already an end of interaction pending, cancel it
            clearTimeout(this._interactionTimer);
        }
        this._interactionTimer = setTimeout(this.doOnInteractionEnd.bind(this), 500);

        // Don't continue drawing right away, as click events for instance would be re-captured right away
        // Just use a 0-delay so continuing drawing will be done right after all events have been processed
        setTimeout(() => this._drawingManager.continue(), 0);
    }

    protected doOnInteractionEnd() {
        super.doOnInteractionEnd();
        if (this._orientationGizmo) {
            this._orientationGizmo.visible = false;
            this._orientationGizmo.update();
        }
    }

    updateCoordinates(coord: PickedPoint = null, marker3d = false) {
        const p = super.updateCoordinates(coord);
        this._minimapService?.setCursorMarker(p);
        this.setCursorMarker(p, marker3d);
        return p;
    }

    deinit() {
        if (!this._instance) return; // nothing to do

        if (this._cameraMoveFrameReq) {
            this._instance.removeEventListener('before-camera-update', this._cameraMoveFrameReq);
            this._cameraMoveFrameReq = null;
        }

        this._layers.forEach((layer, id) => {
            this.removeLayer(id);
        });

        this._orientationGizmo.dispose();
        this._compassWidget.dispose();
        this._drawingManager.dispose();

        if (this._cursorMarker) this._cursorMarker.remove();

        this._raycaster = null;
        this._orientationGizmo = null;

        this._minimapService?.deinit();
        this._minimapService = null;

        this._seismicService?.deinit();
        this._seismicService = null;

        this._instance.removeEventListener('update-end', this._instanceUpdatedFrameReq);

        super.deinit();

        this._dispatch(giro3d.setInitialized(false));
    }

    clickableObjects() {
        const clickables: THREE.Object3D[] = [];

        for (const layer of this.getAllLayers()) {
            if (isClickable(layer)) {
                const element = layer.getClickableElement();
                if (element && element.visible) {
                    clickables.push(element);
                }
            }
        }

        this._annotationManager.getClickableAnnotations(clickables);

        return clickables;
    }

    private doSelectFeatures(selection: SelectionItem[], at: THREE.Vector3) {
        // Keep track of the layers we updated to minimize rendering
        const highlightedUpdatedLayers = new Set<ColorLayer>();

        // First deselect previously-selected features
        for (const item of this._currentSelection) {
            // Remove previously highlighted features
            for (const vectorLayer of this.getLayersForDataset<VectorLayer>(item.layer)) {
                vectorLayer.resetStyle(item.feature);
                highlightedUpdatedLayers.add(vectorLayer.layer);
            }
        }

        this._currentSelection.length = 0;

        for (const item of selection) {
            // Define our new highlighted style
            // We could use feat.feature.setStyle, but it triggers a lot of `changed` events
            // which lowers performances
            for (const vectorLayer of this.getLayersForDataset<VectorLayer>(selection[0].layer)) {
                item.feature.setStyle(highlightedStyleBuilder(vectorLayer.layerStyle));

                // Store the highlighted features
                highlightedUpdatedLayers.add(vectorLayer.layer);
            }
            this._currentSelection.push(item);
        }

        // Trigger update of the features
        for (const l of highlightedUpdatedLayers) {
            l.source.update();
            this._instance.notifyChange(l);
        }

        this._dispatch(giro3d.selectItems({ at: fromVector3(at), items: selection }));
    }

    updateFeatureCoordinates(dataset: DatasetId, feature: OLFeatureId, coordinates: Coordinate[] | Coordinate[][]) {
        this.getLayersForDataset<VectorLayer>(dataset).forEach((layer) =>
            layer.updateFeatureCoordinates(feature, coordinates)
        );
    }

    getFeatureBoundingBox(dataset: DatasetId, feature: OLFeatureId): THREE.Box3 {
        const allLayers = this.getLayersForDataset<VectorLayer>(dataset);

        if (allLayers.length > 0) {
            return allLayers[0].getFeatureBoundingBox(feature);
        }

        return null;
    }

    getFeatureById(dataset: DatasetId, feature: OLFeatureId): Feature {
        const allLayers = this.getLayersForDataset<VectorLayer>(dataset);

        if (allLayers.length > 0) {
            return allLayers[0].getFeatureById(feature);
        }

        return null;
    }

    getFeatures(id: DatasetId): Feature[] {
        const result: Feature[] = [];

        for (const layer of this.getLayersForDataset<VectorLayer>(id)) {
            result.push(...layer.getFeatures());
        }

        return result;
    }

    protected doUnselectFeatures() {
        for (const item of this._currentSelection) {
            for (const vectorLayer of this.getLayersForDataset<VectorLayer>(item.layer)) {
                // Remove previously highlighted features
                vectorLayer.resetStyle(item.feature);
            }
        }
        if (this._currentSelection.length > 0) {
            this._dispatch(giro3d.unselectItems());
        }
        this._currentSelection.length = 0;
    }

    selectFeature(dataset: DatasetId, feature: Feature, at: THREE.Vector3) {
        const item: SelectionItem = {
            feature,
            layer: dataset,
        };
        this.doSelectFeatures([item], at);
    }

    unselectFeature() {
        this.doUnselectFeatures();
    }

    getIsVectorClickable() {
        return this._clickableVector;
    }

    private setIsVectorClickable(value) {
        this._clickableVector = value;
    }

    // eslint-disable-next-line class-methods-use-this
    setHoveredTooltip(item: HoveredItem, location: { x: number; y: number }) {
        eventBus.dispatch('hovered-item', { item, location });
    }

    removeLayer(datasetId: DatasetId) {
        super.removeLayer(datasetId);

        if (this._minimapService?.getLayersForDataset(datasetId)) {
            this._minimapService.removeLayer(datasetId);
        }

        if (this._seismicService?.getLayersForDataset(datasetId)) {
            this._seismicService.removeLayer(datasetId);
        }

        this.computeSceneVolume();
    }

    async postInit() {
        if (!this._instance) return; // nothing to do

        this.computeSceneVolume();
        this.goToLayersTopdown();

        this.saveControlsState();

        this._cameraMoveFrameReq = this.cameraMoveHandler.bind(this);
        this._instance.addEventListener('before-camera-update', this._cameraMoveFrameReq);
        await this._minimapService?.postInit();
    }

    setMinimapService(minimapService: MinimapService) {
        this._minimapService = minimapService;
    }

    setSeismicService(seismicService: SeismicService) {
        this._seismicService = seismicService;
    }

    /**
     * Creates the graphical representation of the specified dataset.
     */
    async loadDataset(dataset: Dataset, sourceFiles: SourceFile[]): Promise<Layer[]> {
        const layerState = this.getLayerState(dataset.id);

        // TODO: Load alternate seismic to minimap (no plane, highlightable)
        if (canShowInMinimap(layerState)) {
            await this._minimapService?.loadDataset(dataset, sourceFiles);
        }

        const allLayers = await super.loadDataset(dataset, sourceFiles);

        return allLayers;
    }

    private onLayerInitialized(): void {
        this.computeSceneVolume();
    }

    loadDatasetToSeismicView(dataset: Dataset, sourceFiles: SourceFile[]) {
        this.setHoveredTooltip(null, null);
        this._seismicService.loadDataset(dataset, sourceFiles);
    }

    unloadDatasetsFromSeismicView() {
        this._seismicService.getAllLayers().forEach((layer) => {
            layer.setThisVisibility(false).then(() => {
                this._seismicService.removeLayer(layer);
            });
        });
    }

    // Note: contour line related functions are Giro3D service only,
    // as it would not render very well on a minimap.

    private setContourLines(enabled: boolean) {
        if (!this._instance) return;

        this._enableContourLines = enabled;
        if (this._layerManager) this._layerManager.contourLines = enabled;
    }

    private highlight(id: DatasetId) {
        this.getLayersForDataset(id)?.forEach((l) => l.flashHighlight());
    }

    private highlightFile(datasetId: DatasetId, fileId: SourceFileId) {
        this.getLayersForDataset(datasetId)
            .find((l) => l.sourceFileId === fileId)
            .flashHighlight();
    }

    private setContourLinePrimaryInterval(value: number) {
        if (!this._instance) return;

        this._contourLinePrimaryInterval = value;
        if (this._layerManager) this._layerManager.contourLinePrimaryInterval = value;
    }

    private setContourLineSecondaryInterval(value: number) {
        if (!this._instance) return;

        this._contourLineSecondaryInterval = value;
        if (this._layerManager) this._layerManager.contourLineSecondaryInterval = value;
    }

    private setContourLineColor(value: THREE.Color) {
        if (!this._instance) return;

        this._contourLineColor = value;
        if (this._layerManager) this._layerManager.contourLineColor = value;
    }

    private setContourLineOpacity(value: number) {
        if (!this._instance) return;

        this._contourLineOpacity = value;
        if (this._layerManager) this._layerManager.contourLineOpacity = value;
    }

    private setCursorMarker(cursor: { picked: boolean; x: number; y: number; z: number }, show: boolean) {
        if (show && cursor.picked) {
            this._cursorMarker.visible = true;
            this._cursorMarker.position.set(cursor.x, cursor.y, cursor.z);
            this._cursorMarker.updateMatrixWorld();
            this.notifyChange(this._cursorMarker);
        } else if (this._cursorMarker.visible) {
            this._cursorMarker.visible = false;
            this.notifyChange(this._cursorMarker);
        }
    }

    private setCamera(position: THREE.Vector3, target: THREE.Vector3) {
        if (!this._instance) return;
        if (
            position &&
            target &&
            !position.equals(this._cameraPosition.position) &&
            !target.equals(this._cameraPosition.target)
        ) {
            this._controls.setCamera(position, target);
            this._cameraPosition.position.copy(position);
            this._cameraPosition.target.copy(target);
        }
    }

    getControls() {
        return this._controls;
    }

    getElevationProfile(points: THREE.Vector2[], datasets: Dataset[]) {
        const layers = datasets.map((d) => this.getLayersForDataset(d)).flat(1);

        return this._layerManager.sampleElevation(
            points,
            layers.map((l) => l.sourceFileId)
        );
    }
}

const giro3dService = new Giro3dService();

export default giro3dService;

export { Giro3dService };
