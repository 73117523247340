import { createSlice, PayloadAction, createSelector } from '@reduxjs/toolkit';

import { DEFAULT_GIRO3D_SETTINGS } from 'services/Constants';
import {
    DatasetId,
    SerializableBox3,
    SerializableVector3,
    SourceFileId,
    toBox3,
    toVector3,
    PlayState,
} from 'types/common';
import { Feature } from 'ol';
import { View } from 'types/serialization/View';
import { RootState } from 'store';
import { CONTROLS_MODE } from 'services/Controls';
import { Vector3 } from 'three';

export type SelectionItem = { layer: DatasetId; feature: Feature };

export type SelectedItems = { at: SerializableVector3; items: SelectionItem[] };
export type DeserializedSelectedItems = { at: Vector3; items: SelectionItem[] };

export type FollowCamera = {
    state: PlayState;
    modal: boolean;
    datasetId: DatasetId;
    rotateCamera: boolean;
    fixedDepth: boolean;
    depth: number;
    altitude: number;
    speed: number;
    progress: number;
};

export type ContextMenu = {
    open: boolean;
    offset?: { x: number; y: number };
    dataset?: DatasetId;
    sourceFile?: SourceFileId;
    point?: SerializableVector3;
};

export type State = {
    initialized: boolean;
    progress: number;
    selectedItems: SelectedItems;
    controls: {
        mode: CONTROLS_MODE;
        target: SerializableVector3;
    };
    // TODO remove this property since it is not longer necessary
    // now that we are using the Redux store for all layer updates
    revision: number;
    camera: {
        position: SerializableVector3;
        target: SerializableVector3;
    };
    contextTool: ContextMenu;
    sceneVolume: SerializableBox3;
    showMinimap: boolean;
    clickableVectorFeatures: boolean;
    zScale: number;
    showInspector: boolean;
    seismicView: {
        initialized: boolean;
        volume: SerializableBox3;
    };
    followCamera: FollowCamera;
};

const initialState: State = {
    initialized: null,
    progress: undefined,
    selectedItems: { at: [0, 0, 0], items: [] },
    controls: {
        mode: CONTROLS_MODE.PAN,
        target: [0, 0, 0],
    },
    revision: 0,
    showInspector: false,
    camera: null,
    contextTool: { open: false, dataset: null, sourceFile: null, offset: { x: 0, y: 0 }, point: null },
    sceneVolume: null,
    seismicView: {
        initialized: false,
        volume: null,
    },
    showMinimap: false,
    zScale: DEFAULT_GIRO3D_SETTINGS.Z_SCALE,
    clickableVectorFeatures: DEFAULT_GIRO3D_SETTINGS.CLICKABLE_VECTOR_FEATURES,
    followCamera: {
        state: PlayState.Pause,
        modal: false,
        datasetId: undefined,
        rotateCamera: false,
        fixedDepth: false,
        depth: 10,
        altitude: 10,
        speed: 10,
        progress: 0,
    },
};

const self = (store: RootState) => store.giro3d;

// Selectors
export const getZScale = createSelector(self, (s) => s.zScale);
export const isInitialized = createSelector(self, (s) => s.initialized);
export const isSeismicViewInitialized = createSelector(self, (s) => s.seismicView.initialized);
export const getProgress = createSelector(self, (s) => s.progress);
export const getRevision = createSelector(self, (s) => s.revision);
export const getContextMenu = createSelector(self, (s) => s.contextTool);
export const getSelectedItems = createSelector(
    (s) => self(s).selectedItems,
    (selectedItems) => ({
        at: toVector3(selectedItems.at),
        items: selectedItems.items,
    })
);
export const getVolume = createSelector(
    (s) => self(s).sceneVolume,
    (sceneVolume) => toBox3(sceneVolume)
);
export const getSeismicVolume = createSelector(
    (s) => self(s).seismicView.volume,
    (volume) => toBox3(volume)
);
export const isMinimapVisible = createSelector(self, (s) => s.showMinimap);
export const isClickableVectorFeatureEnabled = createSelector(self, (s) => s.clickableVectorFeatures);
export const showInspector = createSelector(self, (s) => s.showInspector);
export const getCamera = createSelector(
    (s) => self(s).camera,
    (camera) => ({
        position: toVector3(camera?.position),
        target: toVector3(camera?.target),
    })
);
export const getControls = createSelector(self, (s) => s.controls);
export const getControlMode = createSelector(getControls, (controls) => controls.mode);
export const getControlsTarget = createSelector(getControls, (controls) => controls.target);

export const getFollowCamera = createSelector(self, (s) => s.followCamera);

// Equality functions
export function equalItems(a: DeserializedSelectedItems, b: DeserializedSelectedItems) {
    return a.items === b.items;
}
export function withinLoadingThreshold(a: number, b: number) {
    return a === b || (a !== 1 && Math.abs(a - b) < 0.05);
}

const slice = createSlice({
    name: 'giro3d',
    initialState,
    reducers: {
        loadView: (state, action: PayloadAction<View>) => {
            const settings = action.payload.view;

            state.clickableVectorFeatures = settings.clickableVector ?? state.clickableVectorFeatures;
            state.showInspector = settings.inspectorVisible ?? state.showInspector;
            state.zScale = settings.zScale ?? state.zScale;
            state.camera = {
                position: settings.camera ? settings.camera : null,
                target: settings.target ? settings.target : null,
            };
        },
        initialized: (state, action: PayloadAction<boolean>) => {
            state.initialized = action.payload;
        },
        seismicViewInitialized: (state, action: PayloadAction<boolean>) => {
            state.seismicView.initialized = action.payload;
        },
        showMinimap: (state, action: PayloadAction<boolean>) => {
            state.showMinimap = action.payload;
        },
        setCamera: (state, action: PayloadAction<{ position: SerializableVector3; target: SerializableVector3 }>) => {
            const { position, target } = action.payload;

            state.camera = {
                position,
                target,
            };
        },
        setControlsMode: (state, action: PayloadAction<CONTROLS_MODE>) => {
            state.controls.mode = action.payload;
        },
        setControlsTarget: (state, action: PayloadAction<SerializableVector3>) => {
            state.controls.target = action.payload;
        },
        volume: (state, action: PayloadAction<SerializableBox3>) => {
            state.sceneVolume = action.payload;
        },
        seismicVolume: (state, action: PayloadAction<SerializableBox3>) => {
            state.seismicView.volume = action.payload;
        },
        setZScale: (state, action: PayloadAction<number>) => {
            state.zScale = action.payload;
        },
        resetZScale: (state) => {
            state.zScale = DEFAULT_GIRO3D_SETTINGS.Z_SCALE;
        },
        progress: (state, action: PayloadAction<number>) => {
            state.progress = action.payload;
        },
        incrementRevision: (state) => {
            state.revision++;
        },
        selectItems: (state, action: PayloadAction<SelectedItems>) => {
            state.selectedItems = action.payload;
        },
        unselectItems: (state) => {
            state.selectedItems = initialState.selectedItems;
        },
        contextMenu: (state, action: PayloadAction<ContextMenu>) => {
            state.contextTool = action.payload;
        },
        clickableVectorFeatures: (state, action: PayloadAction<boolean>) => {
            state.clickableVectorFeatures = action.payload;
        },
        showInspector: (state, action: PayloadAction<boolean>) => {
            state.showInspector = action.payload;
        },
        setFollowCameraModal: (state, action: PayloadAction<boolean>) => {
            state.followCamera.modal = action.payload;
        },
        setFollowCamera: (state, action: PayloadAction<FollowCamera>) => {
            state.followCamera = action.payload;
        },
        setFollowCameraState: (state, action: PayloadAction<PlayState>) => {
            state.followCamera.state = action.payload;
        },
        setFollowCameraProgress: (state, action: PayloadAction<number>) => {
            state.followCamera.progress = action.payload;
        },
        resetFollowCamera: (state) => {
            state.followCamera = initialState.followCamera;
        },
    },
});

export const reducer = slice.reducer;

// Actions
export const loadView = slice.actions.loadView;
export const showMinimap = slice.actions.showMinimap;
export const setProgress = slice.actions.progress;
export const setZScale = slice.actions.setZScale;
export const setVolume = slice.actions.volume;
export const setSeismicVolume = slice.actions.seismicVolume;
export const resetZScale = slice.actions.resetZScale;
export const layerSettingsChanged = slice.actions.incrementRevision;
export const setInitialized = slice.actions.initialized;
export const setSeismicViewInitialized = slice.actions.seismicViewInitialized;
export const setDeserialized = slice.actions.incrementRevision;
export const setContextMenu = slice.actions.contextMenu;
export const selectItems = slice.actions.selectItems;
export const unselectItems = slice.actions.unselectItems;
export const setClickableVectorFeatures = slice.actions.clickableVectorFeatures;
export const setShowInspector = slice.actions.showInspector;
export const setCamera = slice.actions.setCamera;
export const setControlsMode = slice.actions.setControlsMode;
export const setControlsTarget = slice.actions.setControlsTarget;
export const setFollowCameraModal = slice.actions.setFollowCameraModal;
export const setFollowCamera = slice.actions.setFollowCamera;
export const setFollowCameraState = slice.actions.setFollowCameraState;
export const setFollowCameraProgress = slice.actions.setFollowCameraProgress;
export const resetFollowCamera = slice.actions.resetFollowCamera;
