import CogSource from '@giro3d/giro3d/sources/CogSource';
import ElevationLayer from '@giro3d/giro3d/core/layer/ElevationLayer';
import ColorLayer from '@giro3d/giro3d/core/layer/ColorLayer';
import GiroColorMap, { ColorMapMode } from '@giro3d/giro3d/core/layer/ColorMap';

import Dataset, { MultiBandCogDatasetProperties, SingleBandCogDatasetProperties } from 'types/Dataset';

import { getBaseName, SourceFile } from 'types/SourceFile';
import { LAYER_DATA_TYPES } from 'services/Constants';
import LayerBuilder from './LayerBuilder';
import ElevationCogLayer from '../layers/raster/ElevationCogLayer';
import CogLayer from '../layers/raster/CogLayer';
import { BuildContext } from './LayerBuilderFactory';

/**
 * Maps COGs bands with Giro3D channels. Note that this cannot be done automatically by Giro3D
 * because it supposes knowledge of how COGs are laid out by the backend.
 */
function getChannelMapping(properties: MultiBandCogDatasetProperties): number[] {
    // Note that we could theoretically support any number of bands, as long as we select the proper
    // subset of bands to display in Giro3D. One typical example would be RGB + near infrared (NIR),
    // where we would have to decide whether to display the NIR band or not, and in which channel.
    // But here we assume we will only have either RGB or grayscale, and optional alpha bands.
    switch (properties.bands) {
        case 4:
            // R, G, B, A (in this exact order).
            return [0, 1, 2, 3];
        case 3:
            // R, G, B without alpha channel.
            return [0, 1, 2];
        case 2:
            // Grayscale band (band 0) with alpha band (band 1), but we have to triplicate
            // the grayscale band into the R, G, B channels to preserve the grayscale-ness.
            return [0, 0, 0, 1];
        default:
            // Grayscale band without alpha channel. Here we don't have to triplicate the band
            // Because we are not using an alpha channel (which imposes a 4-channel pattern).
            return [0];
    }
}

export default class CogBuilder extends LayerBuilder<SingleBandCogDatasetProperties | MultiBandCogDatasetProperties> {
    constructor(dataset: Dataset, sourceFiles: SourceFile[], context: BuildContext) {
        super(dataset, sourceFiles, context);
        this.checkDownloadLink();
    }

    private async loadGiro3dLayer<TLayer extends ElevationLayer | ColorLayer>(sourceFile: SourceFile): Promise<TLayer> {
        // Authorization headers are set by Giro3d
        const extent = this.computeExtent(sourceFile.geometry);

        const datasetId = this.getDatasetId();

        const source = new CogSource({
            url: this.buildUrl(sourceFile.links.download),
            crs: this.getDatasetProjectionAsString(),
            containsFn: this.getContainsFn(sourceFile.geometry),
            channels:
                this._dataset.datatype === LAYER_DATA_TYPES.SINGLEBANDCOG
                    ? [0]
                    : getChannelMapping(this.getDatasetProperties() as MultiBandCogDatasetProperties),
        });

        let result: ColorLayer | ElevationLayer;

        if (this.isDatasetElevation()) {
            const properties = this.getDatasetProperties() as SingleBandCogDatasetProperties;

            result = new ElevationLayer({
                name: sourceFile.id,
                extent,
                noDataOptions: {
                    replaceNoData: true,
                },
                minmax: { min: properties.min, max: properties.max },
                source,
                colorMap: new GiroColorMap([], 0, 0, ColorMapMode.Elevation),
            });
        } else if (this.getDatasetDatatype() === LAYER_DATA_TYPES.MULTIBANDCOG) {
            result = new ColorLayer({
                name: sourceFile.id,
                extent,
                source,
            });
        } else {
            result = new ColorLayer({
                name: sourceFile.id,
                extent,
                source,
                colorMap: new GiroColorMap([], 0, 0, ColorMapMode.Elevation),
            });
        }

        result.userData.datasetId = datasetId;
        result.userData.sourceFileId = sourceFile.id;

        return result as TLayer;
    }

    protected override async buildLayer(sourceFile: SourceFile): Promise<CogLayer | ElevationCogLayer> {
        const datasetId = this.getDatasetId();
        const readableName = getBaseName(sourceFile);
        const datasetType = this.getDatasetType();
        const sourceFileId = sourceFile.id;
        const instance = this.getInstance();
        const layerManager = this._layerManager;

        const sourceFileWithCRS = {
            ...sourceFile,
            geometry: {
                ...sourceFile.geometry,
                crs: sourceFile.geometry.crs ?? { properties: { name: 'EPSG:4326' }, type: 'name' },
            },
        };

        const getFootprint = () => sourceFileWithCRS.geometry;

        if (this.getDatasetDatatype() === LAYER_DATA_TYPES.MULTIBANDCOG) {
            return new CogLayer({
                layerManager,
                readableName,
                datasetType,
                dispatch: this._dispatch,
                instance,
                datasetId,
                sourceFileId,
                getFootprint,
                createLayer: async () => this.loadGiro3dLayer(sourceFileWithCRS),
                supportsColormap: false,
                supportsMasks: false,
                hostView: this._hostView,
            });
        }

        const properties = sourceFile.properties as SingleBandCogDatasetProperties;

        if (this.isDatasetElevation()) {
            return new ElevationCogLayer({
                layerManager,
                readableName,
                datasetType,
                dispatch: this._dispatch,
                instance,
                datasetId,
                sourceFileId,
                minmax: { min: properties.min, max: properties.max },
                getFootprint,
                createLayer: async () => this.loadGiro3dLayer(sourceFileWithCRS),
                supportsColormap: true,
                supportsMasks: true,
                hostView: this._hostView,
            });
        }
        return new CogLayer({
            layerManager,
            readableName,
            datasetType,
            dispatch: this._dispatch,
            instance,
            datasetId,
            sourceFileId,
            minmax: { min: properties.min, max: properties.max },
            getFootprint,
            createLayer: async () => this.loadGiro3dLayer(sourceFileWithCRS),
            supportsColormap: true,
            supportsMasks: false,
            hostView: this._hostView,
        });
    }
}
