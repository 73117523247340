import { useSelector } from 'react-redux';
import * as giro3d from '../../../redux/giro3d';

const InstanceLoadingIndicator = () => {
    const progress = useSelector(giro3d.getProgress, giro3d.withinLoadingThreshold);

    if (progress && progress < 1) {
        return (
            <div className="map-statusbar-text mx-2">
                <i className="fal fa-spinner fa-pulse" />
                <span className="mx-2">{Math.round(progress * 100)}%</span>
            </div>
        );
    }
    return null;
};

export default InstanceLoadingIndicator;
