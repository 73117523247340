// React
import { useDispatch, useSelector } from 'react-redux';
import { Button, UncontrolledTooltip } from 'reactstrap';

import { DatasetId } from 'types/common';

import * as layers from 'redux/layers';

import { useEventBus } from 'EventBus';
import { useAppSelector } from 'store';
import * as datasetsSlice from 'redux/datasets';
import { getUser } from 'redux/selectors';
import { getBaseName, SourceFile } from 'types/SourceFile';
import { setClickedDataset } from 'redux/actions';
import InlineDropMenu from 'components/InlineDropMenu';
import StateIndicator from 'components/StateIndicator';
import ToggleSwitch from '../../../ToggleSwitch';

export type Props = {
    datasetId: DatasetId;
    sourceFile: SourceFile;
    onEditSourceFile: (sourceFile: SourceFile) => void;
};

const selfRelevantEqual = (id: DatasetId) => (left: DatasetId, right: DatasetId) =>
    left === right || !(left === id || right === id);

const SourceFileListItem = (props: Props) => {
    const { datasetId, sourceFile, onEditSourceFile } = props;
    const dispatch = useDispatch();
    const user = useSelector(getUser);

    const id = sourceFile.id;

    const dataset = useAppSelector(datasetsSlice.get(datasetId));
    const clickedSourceFile = useAppSelector(datasetsSlice.getClickedSourceFile, selfRelevantEqual(id));
    const eventBus = useEventBus();

    const isVisible = useAppSelector(layers.getSourceFileVisibility(id));

    const setVisible = (value: boolean) => {
        dispatch(layers.setSourceFileVisibility({ sourceFileId: id, value }));
    };

    function selectSourceFile() {
        dispatch(setClickedDataset(datasetId, id));
        eventBus.dispatch('highlight-file', { dataset: datasetId, file: id });
    }

    function goToFile() {
        eventBus.dispatch('go-to-file', { dataset: datasetId, file: id });
    }

    const baseName = getBaseName(sourceFile);

    return (
        <div className={`data-item ${clickedSourceFile === id ? 'highlighted' : ''}`}>
            <Button
                className="borderless list-item-title"
                id={`sourcefile-name-${id}`}
                onClick={() => selectSourceFile()}
                onDoubleClick={() => goToFile()}
            >
                <div className="row">
                    <div id={`name-${sourceFile.id}`} className="col text-truncate">
                        {baseName}
                        <UncontrolledTooltip placement="top" target={`name-${sourceFile.id}`}>
                            {baseName}
                        </UncontrolledTooltip>
                    </div>
                    <StateIndicator id={sourceFile.id} state={sourceFile.state} state_msg={sourceFile.state_msg} />
                </div>
            </Button>
            <InlineDropMenu id="library-dataset-actions">
                {/* <div id={`delete-${sourceFile.id}`}>
                    <Button
                        className="borderless red"
                        id="delete-dataset"
                        title={`Delete ${getBaseName(sourceFile)}`}
                        onClick={() => setSourceFileToDelete(dataset)}
                        disabled={!dataset.user_permissions.delete_dataset && !user.is_admin}
                    >
                        <i className="fal fa-trash-can" />
                    </Button>
                </div>
                {!dataset.user_permissions.delete_dataset && !user.is_admin ? (
                    <UncontrolledTooltip target={`delete-${dataset.id}`}>
                        You do not have the permissions to delete this dataset
                    </UncontrolledTooltip>
                ) : null} */}
                <div id={`edit-${dataset.id}`}>
                    <Button
                        className="borderless green"
                        id="edit-dataset"
                        title={`Edit ${getBaseName(sourceFile)}`}
                        onClick={() => onEditSourceFile(sourceFile)}
                        disabled={!dataset.user_permissions.update_dataset && !user.is_admin}
                    >
                        <i className="fal fa-pen" />
                    </Button>
                </div>
                {!dataset.user_permissions.update_dataset && !user.is_admin ? (
                    <UncontrolledTooltip target={`edit-${dataset.id}`}>
                        You do not have the permissions to edit this dataset
                    </UncontrolledTooltip>
                ) : null}
            </InlineDropMenu>
            <ToggleSwitch id={`visible-${id}`} checked={isVisible} onChange={(e) => setVisible(e.target.checked)} />
        </div>
    );
};

export default SourceFileListItem;
