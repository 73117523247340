import React from 'react';
import { useSelector } from 'react-redux';

import { getVersionInfo } from '../../redux/selectors';

// eslint-disable-next-line import/no-unresolved
import versionInfo from '../../version';

const BuildInfo = () => {
    const versionAPI = useSelector(getVersionInfo);

    return (
        <>
            <div className="map-pane-title">Build Information</div>
            <div className="map-pane-body">
                <ul>
                    <li>
                        <b>App</b>
                        <ul>
                            <li>{versionInfo.version}</li>
                            <li>{versionInfo.branch}</li>
                            <li>{versionInfo.hash.substring(0, 8)}</li>
                        </ul>
                    </li>
                    <li>
                        <hr />
                    </li>
                    <li>
                        <b>API</b>
                        <ul>
                            <li>{versionAPI.version}</li>
                            <li>{versionAPI.branch}</li>
                            <li>{versionAPI.hash.substring(0, 8)}</li>
                        </ul>
                    </li>
                </ul>
            </div>
        </>
    );
};

export default BuildInfo;
